import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import Select from "react-select";
import { useQueryClient } from "@tanstack/react-query";

const PaymentDetailsTab = ({ payment }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [paymentMethodType, setPaymentMethodType] = useState(payment?.id ?? 4);
  const [paymentValue, setPaymentValue] = useState(payment?.value);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [error, setError] = useState("");

  const paymentMutation = ApiHook.CallPaymentDetails();

  const [paymentDetails, setPaymentDetails] = useState({
    paymentMethod: payment?.id ? payment?.id : "",
    value: payment?.value ? payment?.value : "",
    clicked: false,
    slug: payment?.slug ? payment?.slug : "",
  });
  console.log(payment,"payment details payment")
  console.log(paymentDetails.paymentMethod, payment?.id, "payment details");
  const paymentMethods = (data) => {
    return data.map((item) => ({
      label: item?.name,
      value: item?.slug,
      id: item?.id,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPaymentDetails((prevCredentials) => ({
      ...prevCredentials, 
      [name]: value,
    })); // Clear any previous errors
  };

  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
    setError(""); // Clear any previous errors
  };

  const changePaymentMethod = (item) => {
    setPaymentMethodType(item?.id);
    setPaymentValue(""); // Clear the payment value when changing payment method
    setError(""); // Clear any previous errors
  };

  const onSubmit = () => {
    if (!paymentValue && (paymentMethodType === 5 || paymentMethodType === 6)) {
      setError("this_field_is_required");
      return;
    }

    let data = {
      paymentMethod: paymentMethodType,
      ...(paymentMethodType === 6 && { paypalAccount: paymentValue }),
      ...(paymentMethodType === 5 && { stripeAccount: paymentValue }),
    };
    paymentMutation.mutate(paymentDetails, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["profile"] });
          toast.success(t(res?.data));
          setIsEditModeEnabled(false);
        } else {
          toast.error(t(res?.description));
        }
      },
      onError: (err) => {
        setError("Failed to update payment details. Please try again.");
      },
    });
  };

  return (
    <div id="fourthTab" className="tabcontent">
      <div className="editSec">
        <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("paymentDetails")}</h3>
      <div className="tabcontent_form_section">
        {paymentMethodType === 5 && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("stripeAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="stripeAccount"
                placeholder="Account ID"
                type="text"
                className="form-control"
                value={paymentValue}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
              {error && (
                <span className="validation-error-message">{t(error)}</span>
              )}
            </div>
          </div>
        )}
        {paymentMethodType === 6 && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("paypalAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="paypalAccount"
                type="text"
                className="form-control"
                value={paymentValue}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
              {error && <p className="text-danger">{t(error)}</p>}
            </div>
          </div>
        )}
        <div className="mb-3 row tabBlockClass">
          <label className="col-sm-3 col-form-label labelWidthClass">
            {t("paymentMethod")}
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <select
              name="paymentMethod" // Add a name to your select element
              className="form-select"
              disabled={!isEditModeEnabled}
              value={paymentDetails.paymentMethod || ""} // Set the value of the select based on state
              onChange={handleChange}
            >
              <option value="" disabled>
                Select{" "}
              </option>
              {payment?.options.map((option, key) => (
                <option key={key} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {paymentDetails.paymentMethod == "2" && (
              <div className="mb-3 row tabBlockClass">
                <label className="col-sm-3 col-form-label labelWidthClass">
                  {t("address")}:
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <input
                    name="value"
                    type="text"
                    className="form-control"
                    disabled={!isEditModeEnabled}
                    value={paymentDetails.value}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
        >
          <SubmitButton
            id={"1"}
            isSubmitting={!isEditModeEnabled}
            click={onSubmit}
            text="update"
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsTab;
