import { NavLink, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { Player, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

const SingleLearning = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = ApiHook.CallSingleVideos(id);
  if (isLoading) {
    return (
      <>
        <Loader type="bubble-scale" bgColor={"black"} size={30} />
      </>
    );
  } else if (data && !data.data.status) {
    return (
      <>
        <div className="page_head_top">{t("video")}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>{t(data?.data?.data?.description)}</h6>
        </div>
      </>
    );
  }
  return (
    <>
      {/* <div className="page_head_top">{t("video")}</div> */}
      <div className="react-player">
        {/* <ReactPlayer
          light={
            <img
              // src={data?.data?.data?.image ? data.data.data.image : ""}
              alt={data?.data?.data?.name ? data.data.data.name : "Video"}
            />
          }
          url={data?.data?.data?.url ? data?.data?.data?.url : ""}
          width="100%"
          height="100%"
        /> */}
        <Player
          autoPlay
          src={data?.data?.data?.url ? data?.data?.data?.url : ""}
        >
          <ControlBar autoHide={false} className="my-class" />
        </Player>
      </div>
    </>
  );
};

export default SingleLearning;
