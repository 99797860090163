import { NavLink } from "react-router-dom";
import { useState } from "react";

const VideoList = ({ data }) => {
  const itemsPerPage = 10; // Adjust based on your preference
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="shoping_page_section">
      <div className="row">
        {currentItems?.map((video, index) => (
          <div className="col-xl-3 col-lg-4 col-md-4" key={index}>
            <div className="product_box">
              <NavLink to={`/videos-user/${video.id}`}>
                <div className="product_box_image">
                  <img
                    src={video.image ?? "/images/product3.jpg"}
                    alt="product"
                  />
                </div>
              </NavLink>
              <div className="product_box_content">
                <div className="product_box_head">
                  <NavLink to={`/videos-user/${video.id}`}>{video.name}</NavLink>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <button key={number} onClick={() => paginate(number + 1)}>
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoList;
