import React from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import Loader from "react-js-loader";
import VideoList from "../../components/videos/VideoList";

const Learning = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user?.loginResponse?.user);

  const { data, isLoading } = ApiHook.CallVideos();
  console.log("data", data);
  if (isLoading) {
    return (
      <>
        <Loader type="bubble-scale" bgColor={"black"} size={30} />
      </>
    );
  } else if (data && !data.data.status) {
    return (
      <>
        <div className="page_head_top">{t("video")}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>{t(data?.data?.data?.description)}</h6>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="page_head_top">{t("video")}</div>
      <VideoList data={data.data.data ?? []} />
    </>
  );
};

export default Learning;
