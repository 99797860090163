// const BASE_URL = "http://192.168.10.40:5012/node_api/";
// const DEFAULT_KEY = '63616a98-4a55-4c92-8b42-99c6074e3079'
// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"
// const app_status = "DEMO";
// const WALLET_CONNECT_PROJECT_ID = "79cc1fed65c1e0011808ed5800572159";

//DEMO
// const BASE_URL = "http://162.19.146.135:1458/node_api/";
// const DEFAULT_KEY = "63616a98-4a55-4c92-8b42-99c6074e3079";
// const PAYPAL_CLIENT_ID =
//   "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";
// const app_status = "DEMO";
// const WALLET_CONNECT_PROJECT_ID = "79cc1fed65c1e0011808ed5800572159";


//LIVE
const BASE_URL = "https://admin.myfreedomai.com/node_api/";
const DEFAULT_KEY = "63616a98-4a55-4c92-8b42-99c6074e3079";
const PAYPAL_CLIENT_ID =
  "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";
const app_status = "LIVE";
const WALLET_CONNECT_PROJECT_ID = "ddba342e1a9737f4b6e5f178b303ab82";

export {
  BASE_URL,
  DEFAULT_KEY,
  PAYPAL_CLIENT_ID,
  app_status,
  WALLET_CONNECT_PROJECT_ID,
};
