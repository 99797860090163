import React, { useEffect, useState } from "react";
import usdt_abi from "./abi/bsc_usdt";
import test_usdt_bsc_abi from "./abi/bsc_usdt_test";
import { app_status } from "../../../config/config";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { parseUnits } from "viem";
import {
  useAccount,
  useTransactionConfirmations,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

const ConnectMetamask24 = ({
  oninitiate,
  onsubmit,
  amount,
  toAddress,
  title,
  style,
  metaMaskLoading = false,
  disabled = false,
}) => {
  // amount = 0.001;
  // toAddress = "0x4d32476028b0517b980071fe78287302B1b566ea";


  console.log({ amount });
  const usdt_bsc = "0x55d398326f99059fF775485246999027B3197955";
  const usdt_test_bsc = "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48";

  const contractaddress = app_status == "LIVE" ? usdt_bsc : usdt_test_bsc;
  const contractabi = app_status == "LIVE" ? usdt_abi : test_usdt_bsc_abi;
  const { open, close } = useWeb3Modal();
  const { address, isConnecting, isDisconnected } = useAccount();

  const {
    data: hash,
    writeContract,
    isPending,
    onSccess,
    error,
  } = useWriteContract();

  const {
    data: successData,
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirmed) {
      console.log("---SUCCESS----", { isConfirmed, successData });
      onsubmit({
        status: true,
        message: "Transaction Success.",
        data: successData,
      });
    }
  }, [isConfirmed, successData]);

  useEffect(() => {
    if (error) {
      // console.log("ERROR----", error.shortMessage || error.message);
      onsubmit({
        status: false,
        message: error.shortMessage || error.message,
        data: error.shortMessage || error.message,
      });
    }
  }, [error]);

  const value = parseUnits(amount.toString(), "18");

  const startTransaction = () => {
    if (!address) {
      open();
    } else {
      writeContract(
        {
          abi: contractabi,
          address: contractaddress,
          functionName: "transfer",
          args: [toAddress, value],
        },
        {
          onSettled: (hash) => {
            // console.log("SETTLED", hash);
            if (hash) {
              oninitiate({
                status: true,
                message: "Transaction initiated.",
                hash,
              });
            }
          },
          onError: (error) => {
            // console.log("ERROR----", error);
            onsubmit({
              status: false,
              message: error.shortMessage || error.message,
            });
          },
          // onSuccess: (result) => {
          //   // console.log("SUCCESS----", result);
          //   onsubmit({
          //     status: true,
          //     message: "Transaction Success.",
          //     data: result,
          //   });
          // },
        }
      );
    }
  };

  return (
    <div>
      <button
        // disabled={isPending || isConfirming || metaMaskLoading || disabled}
        className="btn btn-sm btn-primary mt-3"
        onClick={startTransaction}
        style={{
          border: "2px solid black",
          paddingRight: 50,
          paddingLeft: 50,
          ...style,
        }}
      >
        {metaMaskLoading ? "proccessing" : title ?? "Send USDT"}
      </button>
    </div>
  );
};

export default ConnectMetamask24;
