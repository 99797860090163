const Timeline = ({ data = {} }) => {
  console.log(data, "data timeline");
  return (
    <div className="timelinenew">
      <h6>Bonus Details</h6>
      <div class="outer">
        {data.bonusDetails?.map((item, index) => {
          return (
            <div class="card" key={index}>
              <div class="info">
                <h3 class="title">{item.name}</h3>
                <p>{item.date}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
